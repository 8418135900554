<!--
 * @Description: 银行码配置
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-27 11:05:27
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-07 15:03:51
-->
<template>
  <div class='bankCodeSet'>
    <h5 class="title">银行码配置</h5>
    <productInfo v-if="action === 0" @toConfig="getProps"/>
    <startSet v-if="action === 1" @toConfig="getProps"/>
    
  </div>
</template>

<script>
const productInfo = () => import('./components/productInfo.vue')
const startSet = () => import('./components/startSet.vue')
export default {
  //import引入的组件需要注入到对象中才能使用
  name: '',
  components: {
    productInfo,
    startSet
  },
  data() {
    //这里存放数据
    return {
      action: 0
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取组件传值
    getProps: function(index){
      this.action = index;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>